
export default {
    "danger": {background: "#f87171", color: "white"},
    "warning": {background: "#facc15", color: "white"},
    "orange": {background: "#f18A00", color: "white"},
    "muted": {background: "#d6d3d1", color: "black"},
    "primary": {background: "steelblue", color: "white"},
    "primary-subtle": {background: "#d6e3f0", color: "#4682b4"},
    "dark": {background: "#373e45", color: "white"},
    "info": {background: "#0dcaf0", color: "black"},
    "light": {background: "#e9ecef", color: "black"},
    "secondary": {background: "#6c757d", color: "white"},
    "success": {background: "#198754", color: "white"},
    "green": {background: "#50C878", color: "white"},
    "veryhigh": {background: "#f18A00", color: "white"},
    "deleted": {background: "var(--bs-gray-500)", color: "white"},
    "evaluated": {background: "#9DBFAF", color: "white"},
};
